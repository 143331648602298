
import React, { useState, useEffect } from 'react';
import Head from "next/head";
import AOS from 'aos';
import * as Sentry from "@sentry/nextjs";

import { fetchPublicAPI, fetchInternalAPI } from '../api'
import { useWindowSize } from '../utils/useWindowSize';
import { DetailHeader, DetailContent, CallbackForm, RelatedArticles, NotFoundHeader } from "../components/";

const MOBILE_WDITH = 576;

const Detail = ({ article }) => {
  const [ articleData, setArticleData ] = useState()
  const [ allArticles, setAllArticles ] = useState();
  const { width } = useWindowSize();

  useEffect(() => {
    (async () => {
      if (article) {
        setArticleData(article[0]);
        const article_slugs = await fetchPublicAPI({type:'list_of_slugs',query: {list_of_slugs:null}});
        var keys = Object.keys(article_slugs);
        var random1 = article[0]?.slug;
        var x = 0;
        while( random1 == article[0]?.slug && x < 5 ){
          random1 = article_slugs[keys[ keys.length * Math.random() << 0]].slug;
          x++;
        }
        var random2 = random1;
        var x = 0;
        while((random2 == random1 || random2 == article[0]?.slug ) && x < 5){
          random2 = article_slugs[keys[ keys.length * Math.random() << 0]].slug;
          x++;
        }
        const articles = await fetchPublicAPI({type:'article', query:{slug:{value:[random1, random2], operator: 'IN'}}});
        setAllArticles(articles);
      }
    })()
  }, [article])

  useEffect(() => {
    if (!width) return;
    AOS.init({
      duration: 1000,
      offset: width > MOBILE_WDITH ? 120 : 0,
      disable: 'mobile'
    });
    AOS.refresh();
  }, [width])

  if (!articleData) return (
    <>
      <Head>
          <meta property="og:title" content="Charger"></meta>
          <meta property="og:image" content="/static/images/battery-2x-pink-bg.png"></meta>
          <meta property="og:description" content="Charger magazín"></meta>
          <meta property="og:url" content={`${process.env.NEXT_PUBLIC_DRUPAL_API_URL}`}></meta>
      </Head>
      <NotFoundHeader width={width} />
      <CallbackForm />
    </>
  );
  return (
    <>
      <Head>
          <title>{articleData.title} | Charger</title>
          <meta property="og:title" content={`${articleData.title} | Charger`}></meta>
          <meta property="og:description" content={articleData.perex || articleData.title}></meta>
          <meta property="og:image" content={`${process.env.NEXT_PUBLIC_DRUPAL_API_URL + articleData.preview_picture.url}`}></meta>
          <meta property="og:url" content={`${process.env.NEXT_PUBLIC_DRUPAL_API_URL}/${articleData.slug}`}></meta>
      </Head>
      <DetailHeader articleData={articleData} width={width} />
      <DetailContent text={articleData.text} wpId={articleData.wp_id} />
      <RelatedArticles articles={allArticles} width={width} />
      <CallbackForm />
    </>
  );
};

export default Detail;

Detail.getInitialProps = async (ctx) => {
  // In case this is running in a serverless function, await this in order to give Sentry
  // time to send the error before the lambda exits
  await Sentry.captureUnderscoreErrorException(ctx);

  const result = await fetchInternalAPI({type:'article',query:{slug: { value:`${ctx.query.slug}`}}});
  return { article: result }
}



